import { Box, Flex, Heading, SimpleGrid, Text } from "@chakra-ui/react"
import { navigate, PageProps } from "gatsby"
import React from "react"
import { SubmitHandler, useForm } from "react-hook-form"
import { reserveBoat } from "src/client/lambdas"
import ReserveNavigation from "src/components/ReserveNavigation"
import ReserveTemplate, { ReserveBoatRequest, ReserveFormInputs } from "src/components/ReserveTemplate"
import useLocalStorage from "src/lib/hooks/useLocalStorage"

const ReservePayment = (props: PageProps<{ boatUid: string }>) => {
  const { boatUid } = props.params

  const [reserveFormValues, setReserveFormValues] = useLocalStorage<ReserveFormInputs>("reserveFormValues")

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<ReserveFormInputs>({ defaultValues: reserveFormValues })

  if (!reserveFormValues) {
    navigate("/")
    return null
  }

  const onSubmit: SubmitHandler<ReserveFormInputs> = async (values) => {
    setReserveFormValues(values)
    const reserveBoatRequest: ReserveBoatRequest = {
      ...values,
      boatName: "TEST",
    }
    await reserveBoat(reserveBoatRequest)
    // navigate(`/boats/${boatUid}/reserve-payment?offerId=${offerId}`)
  }

  return (
    <ReserveTemplate boatUid={boatUid} step={3}>
      <Box borderWidth="1px" borderColor="#EEEEEE" p={[3, 4]}>
        <Heading as="h3" variant="h3" size="md">
          Your details
        </Heading>
        <SimpleGrid columns={[1, 3]} mt={3} rowGap={3}>
          <Flex flexDirection="column">
            <Text fontWeight="bold">Name</Text>
            <Text>{reserveFormValues.name}</Text>
          </Flex>
          <Flex flexDirection="column">
            <Text fontWeight="bold">E-mail</Text>
            <Text>{reserveFormValues.email}</Text>
          </Flex>
          <Flex flexDirection="column">
            <Text fontWeight="bold">Phone</Text>
            <Text>{reserveFormValues.phone === "" ? "-" : reserveFormValues.phone}</Text>
          </Flex>
          <Flex gridColumnStart={1} gridColumnEnd={[2, 4]} flexDirection="column">
            <Text mt={[0, 4]} fontWeight="bold">
              Message
            </Text>
            <Text>{reserveFormValues.message === "" ? "-" : reserveFormValues.message}</Text>
          </Flex>
        </SimpleGrid>
      </Box>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box mt={4}>
          <Heading as="h3" variant="h3" size="md">
            Bank Transfer
          </Heading>
        </Box>
        <ReserveNavigation
          back="< Back: Your Preferences"
          next="Confirm Booking"
          isSubmitting={isSubmitting}
          timesUp={false}
          mt={6}
        />
      </form>
    </ReserveTemplate>
  )
}

export default ReservePayment
